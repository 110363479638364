import { useFormState } from 'react-final-form';
import Button from 'react-bootstrap/Button';
import { FC } from 'react';

const SubmitButton: FC = () => {
  const formState = useFormState();

  return (
    <Button
      type="submit"
      variant="success"
      size="lg"
      className="w-100 rounded-pill d-flex justify-content-center"
      disabled={formState.submitting}
    >
      Create my account
    </Button>
  );
};

export { SubmitButton };

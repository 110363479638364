import { useRef, FC } from 'react';
import { ModalFlatType } from '@edikk-edikk/base/dist/js/components/modal-flat/types/ModalFlatType';
import { useNetworkService } from '@edikk-edikk/base/dist/js/components/network-service';
import { useNotifier } from '@edikk-edikk/notifier/hooks/useNotifier';
import { FormValuesType } from 'srcJs/components/support-modal/types/FormValuesType';
import { Trigger } from 'srcJs/components/support-modal/components/Trigger';
import { Modal } from 'srcJs/components/support-modal/components/Modal';
import { SUPPORT_MODAL_SAVE_URL } from 'srcJs/components/support-modal/constants';
import { toFormData } from 'axios';

const SupportModal: FC = () => {
  const refModalFlat = useRef<ModalFlatType>();
  const networkService = useNetworkService();
  const { notifierShow } = useNotifier();

  const handlerClick = (): void => {
    refModalFlat.current.show();
  };

  const handlerSubmit = async (values: FormValuesType): Promise<object | void> => networkService.post(
    SUPPORT_MODAL_SAVE_URL,
    toFormData(values),
  ).then((response) => {
    if (!response.status && response.formErrors) {
      return Promise.resolve(response.formErrors);
    }
    notifierShow({
      id: 'support-modal',
      message: 'Thank you, we have received your request',
    });
    refModalFlat.current.hide();
    return Promise.resolve();
  }).catch((error) => {
    notifierShow({
      id: 'support-modal',
      message: error.message,
    });
    return Promise.resolve();
  });

  return (
    <>
      <Trigger onClick={handlerClick} />
      <Modal onSubmit={handlerSubmit} ref={refModalFlat} />
    </>
  );
};

export { SupportModal };

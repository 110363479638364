import { FC, useRef } from 'react';
import { useBaseContext } from 'moduleBase/hooks/use-base-context';
import { PayButtonModal } from 'moduleAdvert/components/pay-button/PayButtonModal';
import { PayButtonModalType } from 'moduleAdvert/components/pay-button/types/PayButtonModalType';
import { PayButtonModalTrigger } from 'moduleAdvert/components/pay-button/PayButtonModalTrigger';
import { useHandlerClick } from 'moduleAdvert/components/pay-button/hooks/use-handler-click';

const PayButtonContent: FC = () => {
  const { isGuest } = useBaseContext();
  const refPayButtonModal = useRef<PayButtonModalType>();
  const { isProcess, handlerClick } = useHandlerClick(refPayButtonModal);

  if (isGuest) {
    return null;
  }

  return (
    <>
      <PayButtonModalTrigger isProcess={isProcess} onClick={handlerClick} />
      <PayButtonModal ref={refPayButtonModal} />
    </>
  );
};

export { PayButtonContent };

import { BASE_INIT_URL, BASE_REDUCER_NAME } from 'moduleBase/constants';
import { InitResponseType } from 'moduleBase/actions/types/InitResponseType';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useNetworkService } from '@edikk-edikk/base/dist/js/components/network-service';

const baseInit = createAsyncThunk<InitResponseType>(
  `${BASE_REDUCER_NAME}/init`,
  async () => {
    const response = await useNetworkService().get<InitResponseType>(BASE_INIT_URL);
    return response.data;
  },
);

export { baseInit };

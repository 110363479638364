import { ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';
import { setIsVisibleOnlyContent } from 'moduleBase/reducers/base-reducer';
import { DispatchPropsType } from 'modulePaymentForm/types/DispatchPropsType';
import { paymentFormInit } from 'modulePaymentForm/actions/init';
import { makeCompleted } from 'modulePaymentForm/reducers/payment-form-reducer';

const mapDispatchToProps = (dispatch: Dispatch) => (bindActionCreators<ActionCreatorsMapObject, DispatchPropsType>({
  setIsVisibleOnlyContent,
  init: paymentFormInit,
  makeCompleted,
}, dispatch));

export { mapDispatchToProps };

import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'moduleUserProfile/reducers/map-state-to-props';
import { mapDispatchToProps } from 'moduleUserProfile/reducers/map-dispatch-to-props';
import { DispatchPropsType } from 'moduleUserProfile/types/DispatchPropsType';
import { StateUserProfileType } from 'moduleUserProfile/types/StateUserProfileType';
import { AdvertList } from 'moduleAdvert/components/list/AdvertList';
import Container from 'react-bootstrap/Container';

type PropsType = StateUserProfileType & DispatchPropsType;

const UserProfile: FC<PropsType> = ({
  isInit,
  adverts,
  init,
}) => {
  useEffect(() => {
    init();
  }, []);

  if (!isInit) {
    return null;
  }

  return (
    <Container fluid="lg">
      <AdvertList items={adverts} />
    </Container>
  );
};

export default connect<StateUserProfileType, DispatchPropsType>(
  mapStateToProps,
  mapDispatchToProps,
)(UserProfile);

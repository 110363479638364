import { FC } from 'react';
import { Link } from 'react-router-dom';
import { USER_REGISTRATION_PATH } from 'moduleUser/constants';

const RegistrationInfo: FC = () => (
  <>
    <p className="text-center">
      Before you will be able to add your advertisement you need to sign in or
    </p>
    <Link to={USER_REGISTRATION_PATH} className="btn btn-outline-success btn-lg w-100 rounded-pill">
      Create a new account
    </Link>
  </>
);

export { RegistrationInfo };

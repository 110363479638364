import { useEffect, useState } from 'react';
import { ScrollPositionData } from 'srcJs/hooks/use-scroll-position/ScrollPositionData';

const useScrollPosition = (): ScrollPositionData => {
  const [data, setData] = useState<ScrollPositionData>({
    previous: { scrollY: 0, scrollX: 0 },
    current: { scrollY: 0, scrollX: 0 },
  });

  const handlerScroll = () => {
    setData((dataPrevious) => ({
      previous: {
        scrollY: dataPrevious.current.scrollY,
        scrollX: dataPrevious.current.scrollX,
      },
      current: {
        scrollY: window.scrollY,
        scrollX: window.scrollX,
      },
    }));
  };

  useEffect(() => {
    window.addEventListener('scroll', handlerScroll);

    return () => {
      window.removeEventListener('scroll', handlerScroll);
    };
  }, []);

  return data;
};

export { useScrollPosition };

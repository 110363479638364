import { FC } from 'react';
import { Label } from '@edikk-edikk/form/fields/Label';
import { FilePickerSimple } from '@edikk-edikk/form/fields/file-picker-simple/FilePickerSimple';

const FieldFile: FC = () => (
  <div>
    <Label label="Attachment" />
    <FilePickerSimple name="file" text="Choose attachment" availableExtensions={['jpg', 'png']} />
  </div>
);

export { FieldFile };

import { createSlice } from '@reduxjs/toolkit';
import { HOME_DEFAULT_REDUCER_NAME } from 'moduleHomeDefault/constants';
import { StateHomeDefaultType } from 'moduleHomeDefault/types/StateHomeDefaultType';
import { homeDefaultInit } from 'moduleHomeDefault/actions/init';
import { homeDefaultLoadMore } from 'moduleHomeDefault/actions/load-more';

const initialState: StateHomeDefaultType = {
  isInit: false,
  isFetching: false,
};

const homeDefaultSlice = createSlice({
  name: HOME_DEFAULT_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(homeDefaultInit.fulfilled, (state, action): void => {
      state.isInit = true;
      state.items = action.payload.items;
      state.pagination = action.payload.pagination;
    }).addCase(homeDefaultLoadMore.pending, (state): void => {
      state.isFetching = true;
    }).addCase(homeDefaultLoadMore.fulfilled, (state, action): void => {
      state.isFetching = false;
      state.items = [...state.items, ...action.payload.items];
      state.pagination = action.payload.pagination;
    });
  },
});

const homeDefaultReducer = homeDefaultSlice.reducer;

export { homeDefaultReducer };

import { FC } from 'react';
import { Form } from 'react-final-form';
import { SubmitButton } from 'moduleUserLogin/components/SubmitButton';
import { Fields } from 'moduleUserLogin/components/Fields';
import { FormValuesType } from 'moduleUserLogin/types/FormValuesType';

type PropsType = {
  onSubmit: (values: FormValuesType) => Promise<object | void>;
};

const LoginForm: FC<PropsType> = ({
  onSubmit,
}) => (
  <Form<FormValuesType>
    onSubmit={onSubmit}
    render={(form) => (
      <form onSubmit={form.handleSubmit}>
        <Fields />
        <SubmitButton />
      </form>
    )}
  />
);

export { LoginForm };

import { FC } from 'react';
import { Label } from '@edikk-edikk/form/fields/Label';
import { TextArea } from '@edikk-edikk/form/fields/TextArea';

const FieldDescription: FC = () => (
  <div className="my-3">
    <Label label="Description" isRequired />
    <TextArea name="description" placeholder="Describe your problem in detail" rows={5} />
  </div>
);

export { FieldDescription };

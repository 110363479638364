import { FC } from 'react';
import {
  AdvertBottomSheetDetailContextType,
} from 'moduleAdvertBottomSheetDetail/contexts/AdvertBottomSheetDetailContextType';
import { AdvertBottomSheetDetailContext } from 'moduleAdvertBottomSheetDetail/contexts/AdvertBottomSheetDetailContext';
import { connect, useDispatch } from 'react-redux';
import { StateAdvertBottomSheetDetailType } from 'moduleAdvertBottomSheetDetail/types/StateAdvertBottomSheetDetailType';
import { DispatchPropsType } from 'moduleAdvertBottomSheetDetail/types/DispatchPropsType';
import { mapStateToProps } from 'moduleAdvertBottomSheetDetail/reducers/map-state-to-props';
import { mapDispatchToProps } from 'moduleAdvertBottomSheetDetail/reducers/map-dispatch-to-props';
import { Content } from 'moduleAdvertBottomSheetDetail/components/Content';
import { hide, show } from 'moduleAdvertBottomSheetDetail/reducers/advert-bottom-sheet-detail-reducer';
import { BottomSheet } from '@edikk-edikk/bottom-sheet/dist/BottomSheet';

type PropsType = StateAdvertBottomSheetDetailType & DispatchPropsType;

const AdvertBottomSheetDetail: FC<PropsType> = ({
  id,
  isVisible,
  isInit,
  advert,
  load,
  truncate,
  changeIsBought,
}) => {
  const dispatch = useDispatch();

  const bottomSheetShow = () => {
    dispatch(show());
  };

  const bottomSheetHide = () => {
    dispatch(hide());
  };

  const handlerShowStart = () => {
    load(id);
  };

  const handlerHideEnd = () => {
    truncate();
  };

  const advertBottomSheetDetailContextValue: AdvertBottomSheetDetailContextType = {
    isInit,
    advert,
    changeIsBought,
  };

  return (
    <BottomSheet
      isVisible={isVisible}
      show={bottomSheetShow}
      hide={bottomSheetHide}
      isFullHeight={isInit}
      onShowStart={handlerShowStart}
      onHideEnd={handlerHideEnd}
    >
      <AdvertBottomSheetDetailContext.Provider value={advertBottomSheetDetailContextValue}>
        <Content />
      </AdvertBottomSheetDetailContext.Provider>
    </BottomSheet>
  );
};

export default connect<StateAdvertBottomSheetDetailType, DispatchPropsType>(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(AdvertBottomSheetDetail);

import { FC, MouseEvent } from 'react';
// @ts-ignore
import postCardCss from 'srcCss/modules/post-card.module.scss';
import { AdvertType } from 'moduleAdvert/types/AdvertType';
import { AdvertCardGallery } from 'moduleAdvert/components/card/AdvertCardGallery';
import { useDispatch } from 'react-redux';
import { show } from 'moduleAdvertBottomSheetDetail/reducers/advert-bottom-sheet-detail-reducer';

type PropsType = {
  advert: AdvertType;
};

const AdvertCard: FC<PropsType> = ({
  advert,
}) => {
  const dispatch = useDispatch();

  const handlerClick = (e: MouseEvent) => {
    e.preventDefault();

    dispatch(show(advert.id));
  };

  return (
    <div className={postCardCss.postCard}>
      <div className={postCardCss.postCard__previewHolder}>
        <AdvertCardGallery photo={advert.photo} onClick={handlerClick} />
      </div>
      <div className={postCardCss.postCard__header}>
        <button type="button" className={postCardCss.postCard__title} onClick={handlerClick}>
          {advert.title}
        </button>
      </div>
    </div>
  );
};

export { AdvertCard };

// @ts-ignore
import layoutCss from 'srcCss/modules/layout.module.scss';
import { FC } from 'react';
import { AppRoutes } from 'moduleBase/components/AppRoutes';
import AdvertBottomSheetDetail from 'moduleAdvertBottomSheetDetail/containers/AdvertBottomSheetDetail';
import { useBaseContext } from 'moduleBase/hooks/use-base-context';
import classNames from 'classnames';

const Content: FC = () => {
  const { isVisibleOnlyContent } = useBaseContext();

  return (
    <>
      <div
        className={classNames(
          layoutCss.content,
          { 'py-0': isVisibleOnlyContent },
        )}
      >
        <AppRoutes />
      </div>
      <AdvertBottomSheetDetail />
    </>
  );
};

export { Content };

const PAYMENT_FORM_INIT_URL = '/payment/form/load';

const PAYMENT_FORM_REDUCER_NAME = 'form';
const PAYMENT_FORM_PATH = '/payment/form/:id';

export {
  PAYMENT_FORM_INIT_URL,

  PAYMENT_FORM_REDUCER_NAME,
  PAYMENT_FORM_PATH,
};

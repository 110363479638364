const BASE_INIT_URL = '/init/load';
const BASE_LOGOUT_URL = '/user/auth/logout';

const BASE_REDUCER_NAME = 'base';

export {
  BASE_INIT_URL,
  BASE_LOGOUT_URL,

  BASE_REDUCER_NAME,
};

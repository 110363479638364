import { forwardRef } from 'react';
import { ModalFlat, ModalFlatBody, ModalFlatHeader } from '@edikk-edikk/base/dist/js/components/modal-flat';
import { setValue } from '@edikk-edikk/form/mutators/setValue';
import { ModalFlatType } from '@edikk-edikk/base/dist/js/components/modal-flat/types/ModalFlatType';
import { Form } from 'react-final-form';
import { FormValuesType } from 'srcJs/components/support-modal/types/FormValuesType';
import Container from 'react-bootstrap/Container';
import { FieldEmail } from 'srcJs/components/support-modal/components/FieldEmail';
import { FieldCause } from 'srcJs/components/support-modal/components/FieldCause';
import { FieldDescription } from 'srcJs/components/support-modal/components/FieldDescription';
import { FieldFile } from 'srcJs/components/support-modal/components/FieldFile';
import { SubmitButton } from 'srcJs/components/support-modal/components/SubmitButton';

type PropsType = {
  onSubmit: (values: FormValuesType) => Promise<object | void>;
};

const Modal = forwardRef<ModalFlatType, PropsType>(({
  onSubmit,
}, forwardedRef) => (
  <ModalFlat ref={forwardedRef} id="support-modal">
    <ModalFlatHeader>
      Contact
    </ModalFlatHeader>
    <ModalFlatBody>
      <Form<FormValuesType>
        onSubmit={onSubmit}
        mutators={{
          setValue,
        }}
        render={(form) => (
          <form onSubmit={form.handleSubmit} className="d-flex flex-column flex-grow-1">
            <Container fluid="lg">
              <FieldEmail />
              <FieldCause />
              <FieldDescription />
              <FieldFile />
            </Container>
            <SubmitButton />
          </form>
        )}
      />
    </ModalFlatBody>
  </ModalFlat>
));

export { Modal };

import { FC } from 'react';
// @ts-ignore
import advertCss from 'srcCss/modules/advert.module.scss';
import {
  useAdvertBottomSheetDetailContext,
} from 'moduleAdvertBottomSheetDetail/hooks/use-advert-bottom-sheet-detail-context';

const Title: FC = () => {
  const {
    advert: {
      title,
    },
  } = useAdvertBottomSheetDetailContext();

  return (
    <h1 className={advertCss.advert__title}>
      {title}
    </h1>
  );
};

export { Title };

import { createAsyncThunk } from '@reduxjs/toolkit';
import { useNetworkService } from '@edikk-edikk/base/dist/js/components/network-service';
import { USER_PROFILE_INIT_URL, USER_PROFILE_REDUCER_NAME } from 'moduleUserProfile/constants';
import { InitResponseType } from 'moduleUserProfile/actions/InitResponseType';

const userProfileInit = createAsyncThunk<InitResponseType>(
  `${USER_PROFILE_REDUCER_NAME}/init`,
  async () => {
    const response = await useNetworkService().get<InitResponseType>(USER_PROFILE_INIT_URL);
    return response.data;
  },
);

export { userProfileInit };

import { createAsyncThunk } from '@reduxjs/toolkit';
import { useNetworkService } from '@edikk-edikk/base/dist/js/components/network-service';
import { InitResponseType } from 'modulePaymentForm/actions/types/InitResponseType';
import { PAYMENT_FORM_INIT_URL, PAYMENT_FORM_REDUCER_NAME } from 'modulePaymentForm/constants';

const paymentFormInit = createAsyncThunk<InitResponseType, number>(
  `${PAYMENT_FORM_REDUCER_NAME}/init`,
  async (id: number) => {
    const response = await useNetworkService().get<InitResponseType>(PAYMENT_FORM_INIT_URL, {
      params: {
        id,
      },
    });
    return response.data;
  },
);

export { paymentFormInit };

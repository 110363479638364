import { FC } from 'react';
import { useBaseContext } from 'moduleBase/hooks/use-base-context';
import { Link } from 'react-router-dom';
import { USER_LOGIN_PATH } from 'moduleUser/constants';
import classNames from 'classnames';
// @ts-ignore
import advertCss from 'srcCss/modules/advert.module.scss';

const PayButtonContentForGuest: FC = () => {
  const { isGuest } = useBaseContext();

  if (!isGuest) {
    return null;
  }

  return (
    <Link to={USER_LOGIN_PATH} className={classNames(advertCss.advert__payButton, 'btn btn-unstyled')}>
      Purchasing posts is available only to authorized users
    </Link>
  );
};

export { PayButtonContentForGuest };

import { FC } from 'react';
import Button from 'react-bootstrap/Button';

type PropsType = {
  onClick: () => void;
};

const Trigger: FC<PropsType> = ({
  onClick,
}) => (
  <Button variant="unstyled" className="fs-5 text-info" onClick={onClick}>
    Contact
  </Button>
);

export { Trigger };

import { FC } from 'react';
import { AdvertType } from 'moduleAdvert/types/AdvertType';
// @ts-ignore
import postListCss from 'srcCss/modules/post-list.module.scss';
import { AdvertCard } from 'moduleAdvert/components/card/AdvertCard';

type PropsType = {
  items: AdvertType[];
};

const AdvertList: FC<PropsType> = ({
  items,
}) => {
  if (items.length === 0) {
    return null;
  }

  const advertCardList = items.map((advert) => (
    <AdvertCard key={advert.id} advert={advert} />
  ));

  return (
    <div className={postListCss.postList}>
      {advertCardList}
    </div>
  );
};

export { AdvertList };

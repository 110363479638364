import { store } from 'modules/configureStore';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from 'moduleBase/containers/App';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  (
    <StrictMode>
      <Provider store={store}>
        <BrowserRouter basename={process.env.BASE_URL}>
          <App />
        </BrowserRouter>
      </Provider>
    </StrictMode>
  ),
);

import { FC, useRef } from 'react';
import HTMLReactParser from 'html-react-parser';
// @ts-ignore
import advertCss from 'srcCss/modules/advert.module.scss';
import {
  useAdvertBottomSheetDetailContext,
} from 'moduleAdvertBottomSheetDetail/hooks/use-advert-bottom-sheet-detail-context';
import classNames from 'classnames';

const Description: FC = () => {
  const {
    advert: {
      description,
      isBought,
    },
  } = useAdvertBottomSheetDetailContext();

  const refContent = useRef<HTMLDivElement>();

  if (!description) {
    return null;
  }

  return (
    <div className={advertCss.advertDescription}>
      <div
        className={classNames(
          advertCss.advertDescription__content,
          { [advertCss.advertDescription__content_overflow]: !isBought },
        )}
        ref={refContent}
      >
        {HTMLReactParser(description)}
      </div>
    </div>
  );
};

export { Description };

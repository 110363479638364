import { HOME_DEFAULT_INIT_URL, HOME_DEFAULT_REDUCER_NAME } from 'moduleHomeDefault/constants';
import { InitResponseType } from 'moduleHomeDefault/actions/types/InitResponseType';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useNetworkService } from '@edikk-edikk/base/dist/js/components/network-service';

const homeDefaultInit = createAsyncThunk<InitResponseType>(
  `${HOME_DEFAULT_REDUCER_NAME}/init`,
  async () => {
    const response = await useNetworkService().get<InitResponseType>(HOME_DEFAULT_INIT_URL);
    return response.data;
  },
);

export { homeDefaultInit };

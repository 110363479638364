import {
  FC, PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { HeaderDataContextType } from 'moduleBase/components/header/context/HeaderDataContextType';
import { HeaderDataContext } from 'moduleBase/components/header/context/HeaderDataContext';
import { useSticky } from 'srcJs/hooks/use-sticky';
import { useScrollPosition } from 'srcJs/hooks/use-scroll-position/use-scroll-position';

const HeaderDataProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const isSticky = useSticky();
  const [visibleArea, setVisibleArea] = useState<number>(0);
  const ref = useRef<HTMLDivElement>();
  const { current: { scrollY } } = useScrollPosition();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (!isSticky) {
      setVisibleArea(ref.current.offsetHeight);
    } else if (ref.current.offsetHeight > scrollY) {
      setVisibleArea(ref.current.offsetHeight - scrollY);
    } else {
      setVisibleArea(0);
    }
  }, [scrollY, isSticky]);

  const headerContextValue: HeaderDataContextType = {
    ref,
    isSticky,
    visibleArea,
  };

  return (
    <HeaderDataContext.Provider value={headerContextValue}>
      {children}
    </HeaderDataContext.Provider>
  );
};

export { HeaderDataProvider };

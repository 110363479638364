import { FC } from 'react';
import { usePaymentFormContext } from 'modulePaymentForm/hooks/use-payment-form-context';
import { Loader } from '@edikk-edikk/base';
import { SumupForm } from 'srcJs/components/sumup/SumupForm';

const Content: FC = () => {
  const {
    isInit,
    invoice,
    isCompleted,
    makeCompleted,
  } = usePaymentFormContext();

  if (!isInit) {
    return <Loader />;
  }

  const handlerAfterPay = () => {
    makeCompleted();
  };

  if (isCompleted) {
    return (
      <div className="d-flex flex-column align-items-center">
        <div>
          Thank you, the payment was successful
        </div>
        <div>
          Please refresh the page
        </div>
      </div>
    );
  }

  return (
    <div>
      <SumupForm id={invoice.id} price={invoice.price} onAfterPay={handlerAfterPay} />
    </div>
  );
};

export { Content };

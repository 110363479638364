import { FC } from 'react';
// @ts-ignore
import payButtonCss from 'srcCss/modules/pay-button.module.scss';
import { Button } from 'react-bootstrap';
import { usePayButtonContext } from 'moduleAdvert/components/pay-button/hooks/use-pay-button-context';
import { PulseLoader } from 'react-spinners';

type PropsType = {
  isProcess: boolean;
  onClick: () => void;
};

const PayButtonModalTrigger: FC<PropsType> = ({
  isProcess,
  onClick,
}) => {
  const { price } = usePayButtonContext();

  if (isProcess) {
    return (
      <div className="d-flex">
        <PulseLoader size={10} color="#000" />
      </div>
    );
  }

  return (
    <Button variant="unstyled" onClick={onClick} className={payButtonCss.payButton}>
      Buy for
      <span className="ms-1">
        {price}
      </span>
    </Button>
  );
};

export { PayButtonModalTrigger };

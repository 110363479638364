import { FC } from 'react';
import { Label } from '@edikk-edikk/form/fields/Label';
import { Input } from '@edikk-edikk/form/fields/Input';

const FieldEmail: FC = () => (
  <div className="my-3">
    <Label label="Email" isRequired />
    <Input name="email" placeholder="Enter your email" />
  </div>
);

export { FieldEmail };

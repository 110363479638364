import { configureStore } from '@reduxjs/toolkit';
import { baseReducer } from 'moduleBase/reducers/base-reducer';
import { BASE_REDUCER_NAME } from 'moduleBase/constants';
import { HOME_DEFAULT_REDUCER_NAME } from 'moduleHomeDefault/constants';
import { homeDefaultReducer } from 'moduleHomeDefault/reducers/home-reducer';
import { NOTIFIER_REDUCER_NAME } from '@edikk-edikk/notifier/constants';
import { reducerNotifier } from '@edikk-edikk/notifier/reducers/slice-notifier';
import { ADVERT_BOTTOM_SHEET_DETAIL_REDUCER_NAME } from 'moduleAdvertBottomSheetDetail/constants';
import {
  advertBottomSheetDetailReducer,
} from 'moduleAdvertBottomSheetDetail/reducers/advert-bottom-sheet-detail-reducer';
import { USER_PROFILE_REDUCER_NAME } from 'moduleUserProfile/constants';
import { userProfileReducer } from 'moduleUserProfile/reducers/user-profile-reducer';
import { PAYMENT_FORM_REDUCER_NAME } from 'modulePaymentForm/constants';
import { paymentFormReducer } from 'modulePaymentForm/reducers/payment-form-reducer';

const store = configureStore({
  reducer: {
    [NOTIFIER_REDUCER_NAME]: reducerNotifier,
    [BASE_REDUCER_NAME]: baseReducer,
    [HOME_DEFAULT_REDUCER_NAME]: homeDefaultReducer,
    [ADVERT_BOTTOM_SHEET_DETAIL_REDUCER_NAME]: advertBottomSheetDetailReducer,
    [USER_PROFILE_REDUCER_NAME]: userProfileReducer,
    [PAYMENT_FORM_REDUCER_NAME]: paymentFormReducer,
  },
});

export { store };

import { FC } from 'react';
import { Navigate } from 'react-router';
import { useNetworkService } from '@edikk-edikk/base/dist/js/components/network-service';
import { useNotifier } from '@edikk-edikk/notifier/hooks/useNotifier';
import { ApiError } from '@edikk-edikk/base/dist/js/components/network-service/errors/ApiError';
import { RegistrationForm } from 'moduleUserRegistration//components/RegistrationForm';
import { LoginInfo } from 'moduleUserRegistration/components/LoginInfo';
import { RegistrationResponseType } from 'moduleUserRegistration/types/RegistrationResponseType';
import { USER_REGISTRATION_URL } from 'moduleUserRegistration/constants';
import { LoginInfoHolder } from 'moduleUserRegistration/components/LoginInfoHolder';
import { RegistrationFormHolder } from 'moduleUserRegistration/components/RegistrationFormHolder';
import { UserRegisterHolder } from 'moduleUserRegistration/components/UserRegisterHolder';
import { useReCaptchaContext } from '@edikk-edikk/recaptcha/hooks/use-re-captcha-context';
import { useBaseContext } from 'moduleBase/hooks/use-base-context';

const UserRegistration: FC = () => {
  const networkService = useNetworkService();
  const { isGuest } = useBaseContext();
  const { notifierShow } = useNotifier();
  const { process } = useReCaptchaContext();

  if (!isGuest) {
    return <Navigate to="/" />;
  }

  const handlerSubmitRegistrationForm = async (
    values: object,
  ): Promise<object | void> => {
    const reCaptchaToken = await process();
    try {
      const response = await networkService.post<RegistrationResponseType>(
        USER_REGISTRATION_URL,
        {
          ...values,
          reCaptcha: reCaptchaToken,
        },
      );

      if (response.formErrors) {
        return Promise.resolve(response.formErrors);
      }

      window.location.href = response.data.redirectUrl;
      return Promise.resolve();
    } catch (error) {
      if (!(error instanceof ApiError)) {
        return Promise.reject(error);
      }

      notifierShow({
        id: 'register',
        message: error.message,
      });
      return Promise.reject();
    }
  };

  return (
    <UserRegisterHolder>
      <RegistrationFormHolder>
        <RegistrationForm onSubmit={handlerSubmitRegistrationForm} />
      </RegistrationFormHolder>
      <LoginInfoHolder>
        <LoginInfo />
      </LoginInfoHolder>
    </UserRegisterHolder>
  );
};

export default UserRegistration;

import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ModalFlat, ModalFlatBody } from '@edikk-edikk/base/dist/js/components/modal-flat';
import { ModalFlatType } from '@edikk-edikk/base/dist/js/components/modal-flat/types/ModalFlatType';
import { PayButtonModalType } from 'moduleAdvert/components/pay-button/types/PayButtonModalType';
import { usePayButtonContext } from 'moduleAdvert/components/pay-button/hooks/use-pay-button-context';
import { SumupForm } from 'srcJs/components/sumup/SumupForm';
import { useNotifier } from '@edikk-edikk/notifier/hooks/useNotifier';
import { FinishInvoiceResponseType } from 'moduleAdvert/components/pay-button/types/FinishInvoiceResponseType';

const PayButtonModal = forwardRef<PayButtonModalType>((_, forwardedRef) => {
  const refModalFlat = useRef<ModalFlatType>();
  const [sumupId, setSumupId] = useState<string|null>(null);
  const { price, changeIsBought } = usePayButtonContext();
  const { notifierShow } = useNotifier();

  const show = (id: string): void => {
    refModalFlat.current.show();

    setSumupId(id);
  };

  useImperativeHandle<PayButtonModalType, PayButtonModalType>(forwardedRef, () => ({
    show,
  }), []);

  const handlerExited = (): void => {
    setSumupId(null);
  };

  const handlerAfterPay = (data: FinishInvoiceResponseType) => {
    notifierShow({
      id: 'payButton',
      message: data.message,
    });

    changeIsBought(true);
  };

  return (
    <ModalFlat ref={refModalFlat} onExited={handlerExited}>
      <ModalFlatBody>
        <SumupForm id={sumupId} price={price} onAfterPay={handlerAfterPay} />
      </ModalFlatBody>
    </ModalFlat>
  );
});

export { PayButtonModal };

import { useScrollPosition } from 'srcJs/hooks/use-scroll-position/use-scroll-position';

const useSticky = () => {
  const {
    previous: {
      scrollY: scrollYPrevious,
    },
    current: {
      scrollY: scrollYCurrent,
    },
  } = useScrollPosition();

  if (scrollYCurrent < 1) {
    return false;
  }

  return scrollYPrevious < scrollYCurrent;
};

export { useSticky };

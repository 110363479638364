import { MutableRefObject, useState } from 'react';
import { CreateInvoiceResponseType } from 'moduleAdvert/components/pay-button/types/CreateInvoiceResponseType';
import { CREATE_INVOICE_URL } from 'moduleAdvertBottomSheetDetail/constants';
import { ApiError } from '@edikk-edikk/base/dist/js/components/network-service/errors/ApiError';
import { useNetworkService } from '@edikk-edikk/base/dist/js/components/network-service';
import { useNotifier } from '@edikk-edikk/notifier/hooks/useNotifier';
import { usePayButtonContext } from 'moduleAdvert/components/pay-button/hooks/use-pay-button-context';
import { PayButtonModalType } from 'moduleAdvert/components/pay-button/types/PayButtonModalType';

const useHandlerClick = (refPayButtonModal: MutableRefObject<PayButtonModalType>) => {
  const { id } = usePayButtonContext();
  const [isProcess, setIsProcess] = useState(false);
  const networkService = useNetworkService();
  const { notifierShow } = useNotifier();

  const handlerClick = () => {
    setIsProcess(true);

    networkService.post<CreateInvoiceResponseType>(CREATE_INVOICE_URL, null, {
      params: { id },
    }).then((response) => {
      refPayButtonModal.current.show(response.data.id);
      setIsProcess(false);
    }).catch((error) => {
      setIsProcess(false);

      if (!(error instanceof ApiError)) {
        throw error;
      }

      notifierShow({
        id: 'payButton',
        message: error.message,
      });
    });
  };

  return {
    isProcess,
    handlerClick,
  };
};

export { useHandlerClick };

import { FC } from 'react';
import { Route, Routes } from 'react-router';
import { NotFound } from '@edikk-edikk/base/dist/js/components/error-page/not-found/NotFound';
import HomeDefault from 'moduleHomeDefault/containers/HomeDefault';
import UserLogin from 'modules/user/modules/login/containers/UserLogin';
import { USER_LOGIN_PATH, USER_PROFILE_PATH, USER_REGISTRATION_PATH } from 'moduleUser/constants';
import UserProfile from 'moduleUserProfile/containers/UserProfile';
import UserRegistration from 'moduleUserRegistration/containers/UserRegistration';
import PaymentForm from 'modules/payment/modules/form/containers/PaymentForm';
import { PAYMENT_FORM_PATH } from 'modulePaymentForm/constants';

const AppRoutes: FC = () => (
  <Routes>
    <Route path="/" element={<HomeDefault />} />
    <Route path={USER_LOGIN_PATH} element={<UserLogin />} />
    <Route path={USER_PROFILE_PATH} element={<UserProfile />} />
    <Route path={USER_REGISTRATION_PATH} element={<UserRegistration />} />
    <Route path={PAYMENT_FORM_PATH} element={<PaymentForm />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export { AppRoutes };

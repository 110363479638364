const CREATE_INVOICE_URL = '/invoice/sumup/action/create';
const FINISH_INVOICE_URL = '/invoice/sumup/action/finish';
const ADVERT_BOTTOM_SHEET_DETAIL_LOAD_URL = '/advert/load';

const ADVERT_BOTTOM_SHEET_DETAIL_REDUCER_NAME = 'advertDetailBottomSheet';

export {
  CREATE_INVOICE_URL,
  FINISH_INVOICE_URL,
  ADVERT_BOTTOM_SHEET_DETAIL_LOAD_URL,

  ADVERT_BOTTOM_SHEET_DETAIL_REDUCER_NAME,
};

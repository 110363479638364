import { FC } from 'react';
import { Label } from '@edikk-edikk/form/fields/Label';
import { Select } from '@edikk-edikk/form/fields/Select';

const FieldCause: FC = () => (
  <div className="my-3">
    <Label label="Cause" isRequired />
    <Select
      name="cause"
      placeholder="Not set"
      data={[
        {
          id: 'payment',
          text: 'Payment',
        },
        {
          id: 'seoAudit',
          text: 'SEO audit',
        },
        {
          id: 'seoStrategy',
          text: 'SEO strategy',
        },
      ]}
    />
  </div>
);

export { FieldCause };

import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useLocationStateChangeSubject } from '@edikk-edikk/base/dist/js/hooks/use-location-state-change-subject';
import { LeftMenuType } from 'srcJs/components/left-menu/types/LeftMenuType';

type PropsType = {
  children: ReactNode,
};

const LeftMenu = forwardRef<LeftMenuType, PropsType>(({
  children,
}, forwardedRef) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const uuid = 'leftMenu';
  const location = useLocation();
  const navigate = useNavigate();
  const locationStateChangeSubject = useLocationStateChangeSubject();

  const show = () => {
    setIsVisible(true);
  };

  const hide = () => {
    setIsVisible(false);
  };

  useImperativeHandle<LeftMenuType, LeftMenuType>(forwardedRef, () => ({
    show,
    hide,
  }), []);

  useEffect(() => {
    const subscription = locationStateChangeSubject.subscribe((state) => {
      const leftMenuIds = [...(state?.leftMenuIds || [])];
      const isIncludes = leftMenuIds.includes(uuid);

      if (isIncludes && !isVisible) {
        show();
      } else if (!isIncludes && isVisible) {
        hide();
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [isVisible]);

  const setId = () => {
    const leftMenuIds = [...(location.state?.leftMenuIds || [])];
    if (leftMenuIds.includes(uuid)) {
      return;
    }

    leftMenuIds.push(uuid);

    navigate(null, {
      state: {
        ...location.state,
        leftMenuIds,
      },
    });
  };

  const removeId = () => {
    const leftMenuIds = [...(location.state?.leftMenuIds || [])];
    if (!leftMenuIds.includes(uuid)) {
      return;
    }

    navigate(-1);
  };

  const handlerEnter = () => {
    setId();
  };

  const handlerExited = () => {
    removeId();
  };

  return (
    <Navbar.Offcanvas
      placement="start"
      show={isVisible}
      onHide={hide}
      onEnter={handlerEnter}
      onExited={handlerExited}
      keyboard={false}
      enforceFocus={false}
    >
      <Offcanvas.Body>
        {children}
      </Offcanvas.Body>
    </Navbar.Offcanvas>
  );
});

export { LeftMenu };

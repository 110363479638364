import { Label } from '@edikk-edikk/form/fields/Label';
import { Input } from '@edikk-edikk/form/fields/Input';
import { FC } from 'react';
import { ReCaptchaError } from '@edikk-edikk/recaptcha/components/ReCaptchaError';

const Fields: FC = () => (
  <>
    <div className="mb-3">
      <Label label="Email" isRequired />
      <Input name="email" type="email" placeholder="name@example.com" />
    </div>
    <div className="mb-3">
      <Label label="Password" isRequired />
      <Input name="password" type="password" placeholder="Password" />
    </div>
    <ReCaptchaError />
  </>
);

export { Fields };

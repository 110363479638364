import { FC } from 'react';
import { PayButtonContent } from 'moduleAdvert/components/pay-button/PayButtonContent';
import { PayButtonContentForGuest } from 'moduleAdvert/components/pay-button/PayButtonContentForGuest';
import { PropsType } from 'moduleAdvert/components/pay-button/types/PropsType';
import { PayButtonContextType } from 'moduleAdvert/components/pay-button/contexts/PayButtonContextType';
import { PayButtonContext } from 'moduleAdvert/components/pay-button/contexts/PayButtonContext';

const PayButton: FC<PropsType> = ({
  id,
  price,
  isBought,
  changeIsBought,
}) => {
  if (isBought) {
    return null;
  }

  const payButtonContextValue: PayButtonContextType = {
    id,
    price,
    changeIsBought,
  };

  return (
    <PayButtonContext.Provider value={payButtonContextValue}>
      <PayButtonContent />
      <PayButtonContentForGuest />
    </PayButtonContext.Provider>
  );
};

export { PayButton };

import Container from 'react-bootstrap/Container';
// @ts-ignore
import advertCss from 'srcCss/modules/advert.module.scss';
import { FC } from 'react';
import {
  useAdvertBottomSheetDetailContext,
} from 'moduleAdvertBottomSheetDetail/hooks/use-advert-bottom-sheet-detail-context';
import { Gallery } from 'srcJs/components/gallery/Gallery';
import { Title } from 'moduleAdvertBottomSheetDetail/components/Title';
import { Description } from 'moduleAdvertBottomSheetDetail/components/Description';
import { PayButton } from 'moduleAdvert/components/pay-button/PayButton';
import { Loader } from '@edikk-edikk/base';

const Content: FC = () => {
  const {
    isInit,
    advert,
    changeIsBought,
  } = useAdvertBottomSheetDetailContext();

  if (!isInit) {
    return <Loader />;
  }

  const {
    photo,
    id,
    price,
    isBought,
  } = advert;

  const renderGallery = () => {
    if (!photo) {
      return null;
    }

    return <Gallery photos={[photo]} isInitFancybox />;
  };

  return (
    <Container fluid="lg" className="mb-3">
      <div className={advertCss.advert}>
        {renderGallery()}
        <Title />
        <Description />
        <div className={advertCss.advert__payButtonHolder}>
          <PayButton id={id} price={price} isBought={isBought} changeIsBought={changeIsBought} />
        </div>
      </div>
    </Container>
  );
};

export { Content };

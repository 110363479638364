import { createSlice } from '@reduxjs/toolkit';
import { PAYMENT_FORM_REDUCER_NAME } from 'modulePaymentForm/constants';
import { paymentFormInit } from 'modulePaymentForm/actions/init';
import { StatePaymentFormType } from 'modulePaymentForm/types/StatePaymentFormType';

const initialState: StatePaymentFormType = {
  isInit: false,
  invoice: null,
  isCompleted: false,
};

const baseSlice = createSlice({
  name: PAYMENT_FORM_REDUCER_NAME,
  initialState,
  reducers: {
    makeCompleted: (state) => {
      state.isCompleted = true;
    },
  },
  extraReducers(builder) {
    builder.addCase(paymentFormInit.fulfilled, (state, action) => {
      state.isInit = true;
      state.invoice = action.payload.invoice;
    });
  },
});

const paymentFormReducer = baseSlice.reducer;

const { makeCompleted } = baseSlice.actions;

export {
  paymentFormReducer,
  makeCompleted,
};

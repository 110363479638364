import { FC } from 'react';
import Button from 'react-bootstrap/Button';
import { useFormState } from 'react-final-form';

const SubmitButton: FC = () => {
  const formState = useFormState();

  return (
    <Button
      type="submit"
      variant="success"
      size="lg"
      className="rounded-0 shadow-none mt-auto mt-md-3"
      disabled={formState.submitting}
    >
      Send an appeal
    </Button>
  );
};

export { SubmitButton };

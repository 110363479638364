import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
// @ts-ignore
import authCss from 'srcCss/modules/auth.module.scss';

const UserRegisterHolder: FC<PropsWithChildren> = ({
  children,
}) => (
  <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
    <div className={classNames(authCss.authHolder, 'mb-3')}>
      <Row className="g-0">
        {children}
      </Row>
    </div>
  </div>
);

export { UserRegisterHolder };

import { forwardRef, useRef } from 'react';
import { LeftMenu as LeftMenuDefault } from 'srcJs/components/left-menu/LeftMenu';
import { LeftMenuType } from 'srcJs/components/left-menu/types/LeftMenuType';
import useMergedRefs from '@restart/hooks/useMergedRefs';
// @ts-ignore
import TelegramIcon from 'src/icons/telegram.svg';
import classNames from 'classnames';
// @ts-ignore
import avatarCss from 'srcCss/modules/avatar.module.scss';
import { SupportModal } from 'srcJs/components/support-modal/containers/SupportModal';

const LeftMenu = forwardRef<LeftMenuType>((
  props,
  forwardedRef,
) => {
  const refLeftMenu = useRef<LeftMenuType>();
  const refLeftMenuList = useMergedRefs(refLeftMenu, forwardedRef);

  const handlerClick = () => {
    refLeftMenu.current.hide();
  };

  return (
    <LeftMenuDefault ref={refLeftMenuList}>
      <div className="px-3">
        <SupportModal />
      </div>
      <div className="mt-4 px-3">
        <div className="fs-5 mb-3 text-secondary">
          Follow us
        </div>
        <div className="d-flex gap-4 flex-wrap">
          <a
            href="https://t.me/+FQZ1PAeAdmtkYmU0"
            target="_blank"
            rel="nofollow noreferrer noopener"
            className={classNames(avatarCss.avatar, avatarCss.avatar_xs)}
            onClick={handlerClick}
            aria-label="telegram"
          >
            <TelegramIcon className={avatarCss.avatar__image} />
          </a>
        </div>
      </div>
    </LeftMenuDefault>
  );
});

export { LeftMenu };

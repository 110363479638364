import { FC } from 'react';
import { Form } from 'react-final-form';
import { Fields } from 'moduleUserRegistration/components/Fields';
import { SubmitButton } from 'moduleUserRegistration/components/SubmitButton';

type PropsType = {
  onSubmit: (values) => Promise<object | void>;
};

const RegistrationForm: FC<PropsType> = ({
  onSubmit,
}) => (
  <Form
    onSubmit={onSubmit}
    render={(form) => (
      <form onSubmit={form.handleSubmit}>
        <Fields />
        <SubmitButton />
      </form>
    )}
  />
);

export { RegistrationForm };

import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import Col from 'react-bootstrap/Col';

const LoginInfoHolder: FC<PropsWithChildren> = ({
  children,
}) => (
  <Col
    md={6}
    className={classNames(
      'pb-5 px-4 px-md-5 pt-md-5 pt-3',
      'd-flex justify-content-center flex-column',
      'border-start-md border-top border-top-md-0',
    )}
  >
    {children}
  </Col>
);

export { LoginInfoHolder };

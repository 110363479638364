import { createSlice } from '@reduxjs/toolkit';
import { USER_PROFILE_REDUCER_NAME } from 'moduleUserProfile/constants';
import { StateUserProfileType } from 'moduleUserProfile/types/StateUserProfileType';
import { userProfileInit } from 'moduleUserProfile/actions/init';

const initialState: StateUserProfileType = {
  isInit: false,
};

const userProfileSlice = createSlice({
  name: USER_PROFILE_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(userProfileInit.fulfilled, (state, action): void => {
      state.isInit = true;
      state.adverts = action.payload.adverts;
    });
  },
});

const userProfileReducer = userProfileSlice.reducer;

export { userProfileReducer };

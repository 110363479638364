import { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { StatePaymentFormType } from 'modulePaymentForm/types/StatePaymentFormType';
import { DispatchPropsType } from 'modulePaymentForm/types/DispatchPropsType';
import { mapStateToProps } from 'modulePaymentForm/reducers/map-state-to-props';
import { mapDispatchToProps } from 'modulePaymentForm/reducers/map-dispatch-to-props';
import { PaymentFormContextType } from 'modulePaymentForm/contexts/PaymentFormContextType';
import { PaymentFormContext } from 'modulePaymentForm/contexts/PaymentFormContext';
import { Content } from 'modulePaymentForm/components/Content';

type PropsType = StatePaymentFormType & DispatchPropsType;

const PaymentForm: FC<PropsType> = ({
  isInit,
  invoice,
  isCompleted,
  setIsVisibleOnlyContent,
  init,
  makeCompleted,
}) => {
  const { id } = useParams<'id'>();

  useEffect(() => {
    setIsVisibleOnlyContent(true);

    init(Number(id));
  }, []);

  const paymentFormContextValue: PaymentFormContextType = {
    isInit,
    invoice,
    isCompleted,
    makeCompleted,
  };

  return (
    <PaymentFormContext.Provider value={paymentFormContextValue}>
      <div className="d-flex justify-content-center align-items-center flex-grow-1">
        <Content />
      </div>
    </PaymentFormContext.Provider>
  );
};

export default connect<StatePaymentFormType, DispatchPropsType>(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentForm);

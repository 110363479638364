import { connect } from 'react-redux';
import Notifier from '@edikk-edikk/notifier/containers/Notifier';
import { FC, StrictMode, useEffect } from 'react';
import { StateBaseType } from 'moduleBase/types/StateBaseType';
import { DispatchPropsType } from 'moduleBase/types/DispatchPropsType';
import { HeaderDataProvider } from 'moduleBase/components/header/HeaderDataProvider';
import { Header } from 'moduleBase/components/header/Header';
import { Content } from 'moduleBase/components/Content';
import { mapStateToProps } from 'moduleBase/reducers/map-state-to-props';
import { mapDispatchToProps } from 'moduleBase/reducers/map-dispatch-to-props';
import { BaseContextType } from 'moduleBase/contexts/BaseContextType';
import { BaseContext } from 'moduleBase/contexts/BaseContext';
import { ReCaptcha } from '@edikk-edikk/recaptcha/components/ReCaptcha';
import { useInitSentry } from '@edikk-edikk/sentry/use-init-sentry';
import useCustomEffect from '@restart/hooks/useCustomEffect';
import isEqual from 'lodash/isEqual';

type PropsType = StateBaseType & DispatchPropsType;

const App: FC<PropsType> = ({
  init,
  isInit,
  sentryData,
  reCaptchaData,
  isGuest,
  isVisibleOnlyContent,
}) => {
  const initSentry = useInitSentry(sentryData);

  useEffect(() => {
    init();
  }, []);

  useCustomEffect(() => {
    initSentry();
  }, [sentryData], isEqual);

  if (!isInit) {
    return null;
  }

  const baseContextValue: BaseContextType = {
    isGuest,
    isVisibleOnlyContent,
  };

  return (
    <StrictMode>
      <BaseContext.Provider value={baseContextValue}>
        <ReCaptcha isEnable={reCaptchaData.isEnable} site={reCaptchaData.site}>
          <HeaderDataProvider>
            {/* @ts-ignore */}
            <Notifier top={8} />
            <Header />
            <Content />
          </HeaderDataProvider>
        </ReCaptcha>
      </BaseContext.Provider>
    </StrictMode>
  );
};

export default connect<StateBaseType, DispatchPropsType>(
  mapStateToProps,
  mapDispatchToProps,
)(App);

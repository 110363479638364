import { FC, PropsWithChildren } from 'react';
import Col from 'react-bootstrap/Col';

const RegistrationFormHolder: FC<PropsWithChildren> = ({
  children,
}) => (
  <Col md={6} className="pt-5 px-4 px-md-5 pb-md-5 pb-3">
    <div className="mb-4 fs-2 fw-bold">
      Please, Register
    </div>
    {children}
  </Col>
);

export { RegistrationFormHolder };

import { MouseEvent, FC } from 'react';
import { AdvertType } from 'moduleAdvert/types/AdvertType';
import { Gallery } from 'srcJs/components/gallery/Gallery';

type PropsType = Pick<AdvertType, 'photo'> & {
  onClick: (e: MouseEvent) => void,
};

const AdvertCardGallery: FC<PropsType> = ({
  photo,
  onClick,
}) => {
  if (!photo) {
    return null;
  }

  return (
    <Gallery
      photos={[photo]}
      sm
      renderItem={(photoLocal) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <span onClick={onClick} className="cursor-pointer">
          <img src={photoLocal.url} className="gallery__image gallery__image_rounded" />
        </span>
      )}
    />
  );
};

export { AdvertCardGallery };

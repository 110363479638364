import { ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';
import { DispatchPropsType } from 'moduleHomeDefault/types/DispatchPropsType';
import { homeDefaultInit } from 'moduleHomeDefault/actions/init';
import { homeDefaultLoadMore } from 'moduleHomeDefault/actions/load-more';

const mapDispatchToProps = (dispatch: Dispatch) => (bindActionCreators<ActionCreatorsMapObject, DispatchPropsType>({
  init: homeDefaultInit,
  loadMore: homeDefaultLoadMore,
}, dispatch));

export { mapDispatchToProps };

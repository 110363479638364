import { createAsyncThunk } from '@reduxjs/toolkit';
import { InitResponseType } from 'moduleHomeDefault/actions/types/InitResponseType';
import { HOME_DEFAULT_INIT_URL, HOME_DEFAULT_REDUCER_NAME } from 'moduleHomeDefault/constants';
import { useNetworkService } from '@edikk-edikk/base/dist/js/components/network-service';

const homeDefaultLoadMore = createAsyncThunk<InitResponseType, number>(
  `${HOME_DEFAULT_REDUCER_NAME}/load-more`,
  async (page: number) => {
    const response = await useNetworkService().get<InitResponseType>(
      HOME_DEFAULT_INIT_URL,
      {
        params: { page },
      },
    );
    return response.data;
  },
);

export { homeDefaultLoadMore };

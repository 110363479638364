import { FC } from 'react';
import { Link } from 'react-router-dom';

const LoginInfo: FC = () => (
  <>
    <p className="text-center">
      Here you can create your account in our service.
    </p>
    <p className="text-center">
      Thanks to it you will be able to add as many adverts as you like in fast and easy way!
    </p>
    <p className="text-center">
      Already have an account? Click here to
    </p>
    <Link to="/user/login" className="btn btn-outline-success btn-lg w-100 rounded-pill">
      Back to login page
    </Link>
  </>
);

export { LoginInfo };

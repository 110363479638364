import { FC, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useNetworkService } from '@edikk-edikk/base/dist/js/components/network-service';
import { BASE_LOGOUT_URL } from 'moduleBase/constants';
import { LogoutResponseType } from 'moduleBase/types/LogoutResponseType';

const ActionLogout: FC = () => {
  const [isProcess, setIsProcess] = useState<boolean>(false);
  const networkService = useNetworkService();

  const handlerClick = async () => {
    setIsProcess(true);
    const response = await networkService.post<LogoutResponseType>(BASE_LOGOUT_URL);
    window.location.href = response.data.redirectUrl;
  };

  return (
    <Button variant="unstyled" onClick={handlerClick} disabled={isProcess}>
      <FontAwesomeIcon icon={faArrowRightFromBracket} className="fs-4 text-white" />
    </Button>
  );
};

export { ActionLogout };

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ADVERT_BOTTOM_SHEET_DETAIL_REDUCER_NAME } from 'moduleAdvertBottomSheetDetail/constants';
import { StateAdvertBottomSheetDetailType } from 'moduleAdvertBottomSheetDetail/types/StateAdvertBottomSheetDetailType';
import { advertBottomSheetDetailLoad } from 'moduleAdvertBottomSheetDetail/actions/load';

const initialState: StateAdvertBottomSheetDetailType = {
  isVisible: false,
  isInit: false,
};

const advertBottomSheetDetailSlice = createSlice({
  name: ADVERT_BOTTOM_SHEET_DETAIL_REDUCER_NAME,
  initialState,
  reducers: {
    truncate: (state) => {
      state.isInit = false;
      state.advert = null;
    },
    changeIsBought: (state, action: PayloadAction<boolean>) => {
      state.advert.isBought = action.payload;
    },
    show: (state, action: PayloadAction<number>) => {
      state.isVisible = true;
      state.id = action.payload;
    },
    hide: (state) => {
      state.isVisible = false;
      state.id = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(advertBottomSheetDetailLoad.fulfilled, (state, action) => {
      state.isInit = true;
      state.advert = action.payload.advert;
    });
  },
});

const advertBottomSheetDetailReducer = advertBottomSheetDetailSlice.reducer;

const {
  truncate,
  changeIsBought,
  show,
  hide,
} = advertBottomSheetDetailSlice.actions;

export {
  advertBottomSheetDetailReducer,
  truncate,
  changeIsBought,
  show,
  hide,
};

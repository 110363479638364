import {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Unless } from 'react-if';
import { ERROR_MESSAGE_DEFAULT, Loader } from '@edikk-edikk/base';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import { useNotifier } from '@edikk-edikk/notifier/hooks/useNotifier';
import { useNetworkService } from '@edikk-edikk/base/dist/js/components/network-service';
import { FINISH_INVOICE_URL } from 'moduleAdvertBottomSheetDetail/constants';
import { FinishInvoiceResponseType } from 'moduleAdvert/components/pay-button/types/FinishInvoiceResponseType';

type PropsType = {
  id: string;
  price: string;
  onAfterPay: (data: FinishInvoiceResponseType) => void;
};

const SumupForm: FC<PropsType> = ({
  id,
  price,
  onAfterPay,
}) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isProcess, setIsProcess] = useState<boolean>(false);
  const refSumupCard = useRef<object>();
  const { notifierShow } = useNotifier();
  const networkService = useNetworkService();
  const refScriptTag = useRef<HTMLScriptElement>();

  if (!id) {
    return null;
  }

  const finish = () => {
    networkService.post<FinishInvoiceResponseType>(FINISH_INVOICE_URL, null, {
      params: { id },
    }).then((response) => {
      if (!response.data.isSuccess) {
        notifierShow({
          id: 'sumupForm',
          message: ERROR_MESSAGE_DEFAULT,
        });
        setIsProcess(false);
        return;
      }

      onAfterPay(response.data);
    });
  };

  const handlerResponse = (type: string) => {
    if (type === 'error' || type === 'fail') {
      setIsProcess(false);
      notifierShow({
        id: 'payButton',
        message: ERROR_MESSAGE_DEFAULT,
      });
    }

    if (type === 'invalid') {
      setIsProcess(false);
      return;
    }

    if (type !== 'success') {
      return;
    }

    finish();
  };

  const handlerPaymentMethodsLoad = () => {
    setIsLoaded(true);
  };

  const handlerLoad = () => {
    // @ts-ignore
    refSumupCard.current = SumUpCard.mount({
      id: 'sumup',
      checkoutId: id,
      showFooter: false,
      showSubmitButton: false,
      onResponse: handlerResponse,
      onPaymentMethodsLoad: handlerPaymentMethodsLoad,
    });
  };

  useEffect(() => {
    if (refScriptTag.current) {
      return undefined;
    }

    refScriptTag.current = document.createElement('script');
    refScriptTag.current.src = 'https://gateway.sumup.com/gateway/ecom/card/v2/sdk.js';
    refScriptTag.current.id = 'sumup-script';
    refScriptTag.current.async = true;
    refScriptTag.current.onload = handlerLoad;
    document.body.appendChild(refScriptTag.current);

    return () => {
      if (refScriptTag.current) {
        refScriptTag.current.remove();
      }
    };
  }, []);

  const handlerClick = () => {
    setIsProcess(true);

    // @ts-ignore
    refSumupCard.current.submit();
  };

  return (
    <>
      <Unless condition={isLoaded}>
        <div className="d-flex justify-content-center p-4">
          <Loader />
        </div>
      </Unless>
      <div className={classNames({ 'd-none': !isLoaded })}>
        <div id="sumup" />
        <div className="d-grid">
          <Button disabled={isProcess} onClick={handlerClick} className="mx-4 mb-3 fw-bold">
            Pay
            <span className="ms-1">
              {price}
            </span>
          </Button>
        </div>
      </div>
    </>
  );
};

export { SumupForm };

import { ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';
import { advertBottomSheetDetailLoad } from 'moduleAdvertBottomSheetDetail/actions/load';
import { DispatchPropsType } from 'moduleAdvertBottomSheetDetail/types/DispatchPropsType';
import { changeIsBought, truncate } from 'moduleAdvertBottomSheetDetail/reducers/advert-bottom-sheet-detail-reducer';

const mapDispatchToProps = (dispatch: Dispatch) => (bindActionCreators<ActionCreatorsMapObject, DispatchPropsType>({
  load: advertBottomSheetDetailLoad,
  truncate,
  changeIsBought,
}, dispatch));

export { mapDispatchToProps };

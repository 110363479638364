import { FC } from 'react';
import { Navigate } from 'react-router';
import { ApiError } from '@edikk-edikk/base/dist/js/components/network-service/errors/ApiError';
import { useNetworkService } from '@edikk-edikk/base/dist/js/components/network-service';
import { useNotifier } from '@edikk-edikk/notifier/hooks/useNotifier';
import { RegistrationInfo } from 'moduleUserLogin/components/RegistrationInfo';
import { LoginForm } from 'moduleUserLogin/components/LoginForm';
import { USER_LOGIN_URL } from 'moduleUserLogin/constants';
import { LoginResponseType } from 'moduleUserLogin/types/LoginResponseType';
import { RegistrationInfoHolder } from 'moduleUserLogin/components/RegistrationInfoHolder';
import { LoginFormHolder } from 'moduleUserLogin/components/LoginFormHolder';
import { UserLoginHolder } from 'moduleUserLogin/components/UserLoginHolder';
import { useReCaptchaContext } from '@edikk-edikk/recaptcha/hooks/use-re-captcha-context';
import { useUser } from 'srcJs/components/user/hooks/use-user';
import { FormValuesType } from 'moduleUserLogin/types/FormValuesType';

const UserLogin: FC = () => {
  const networkService = useNetworkService();
  const user = useUser();
  const { notifierShow } = useNotifier();
  const { process } = useReCaptchaContext();

  if (user) {
    return <Navigate to="/" />;
  }

  const handlerSubmitLoginForm = async (values: FormValuesType): Promise<object | void> => {
    const reCaptchaToken = await process();
    try {
      const response = await networkService.post<LoginResponseType>(
        USER_LOGIN_URL,
        {
          ...values,
          reCaptcha: reCaptchaToken,
        },
      );

      if (response.formErrors) {
        return Promise.resolve(response.formErrors);
      }

      window.location.href = response.data.redirectUrl;
      return Promise.resolve();
    } catch (error) {
      if (!(error instanceof ApiError)) {
        return Promise.reject(error);
      }

      notifierShow({
        id: 'login',
        message: error.message,
      });
      return Promise.resolve();
    }
  };

  return (
    <UserLoginHolder>
      <LoginFormHolder>
        <LoginForm onSubmit={handlerSubmitLoginForm} />
      </LoginFormHolder>
      <RegistrationInfoHolder>
        <RegistrationInfo />
      </RegistrationInfoHolder>
    </UserLoginHolder>
  );
};

export default UserLogin;

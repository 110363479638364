import { createAsyncThunk } from '@reduxjs/toolkit';
import { useNetworkService } from '@edikk-edikk/base/dist/js/components/network-service';
import {
  ADVERT_BOTTOM_SHEET_DETAIL_LOAD_URL,
  ADVERT_BOTTOM_SHEET_DETAIL_REDUCER_NAME,
} from 'moduleAdvertBottomSheetDetail/constants';
import { LoadResponseType } from 'moduleAdvertBottomSheetDetail/actions/types/LoadResponseType';

const advertBottomSheetDetailLoad = createAsyncThunk<LoadResponseType, number>(
  `${ADVERT_BOTTOM_SHEET_DETAIL_REDUCER_NAME}/load`,
  async (id: number) => {
    const response = await useNetworkService().get<LoadResponseType>(
      ADVERT_BOTTOM_SHEET_DETAIL_LOAD_URL,
      {
        params: { id },
      },
    );
    return response.data;
  },
);

export { advertBottomSheetDetailLoad };

import { useContext } from 'react';
import { AdvertBottomSheetDetailContext } from 'moduleAdvertBottomSheetDetail/contexts/AdvertBottomSheetDetailContext';
import {
  AdvertBottomSheetDetailContextType,
} from 'moduleAdvertBottomSheetDetail/contexts/AdvertBottomSheetDetailContextType';

const useAdvertBottomSheetDetailContext = () => useContext<AdvertBottomSheetDetailContextType>(
  AdvertBottomSheetDetailContext,
);

export { useAdvertBottomSheetDetailContext };

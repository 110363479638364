import { StateHomeDefaultType } from 'moduleHomeDefault/types/StateHomeDefaultType';
import { DispatchPropsType } from 'moduleHomeDefault/types/DispatchPropsType';
import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'moduleHomeDefault/reducers/map-state-to-props';
import { mapDispatchToProps } from 'moduleHomeDefault/reducers/map-dispatch-to-props';
import Container from 'react-bootstrap/Container';
import { AdvertList } from 'moduleAdvert/components/list/AdvertList';
import { PaginationLoadMore } from '@edikk-edikk/base';

type PropsType = StateHomeDefaultType & DispatchPropsType;

const HomeDefault: FC<PropsType> = ({
  isInit,
  items,
  pagination,
  init,
  loadMore,
  isFetching,
}) => {
  useEffect(() => {
    init();
  }, []);

  if (!isInit) {
    return null;
  }

  const handlerLoadMore = (page: number): void => {
    loadMore(page);
  };

  return (
    <Container fluid="lg">
      <AdvertList items={items} />
      <PaginationLoadMore
        page={pagination.page}
        pageCount={pagination.pageCount}
        pageSize={pagination.pageSize}
        totalCount={pagination.totalCount}
        isFetching={isFetching}
        loadMore={handlerLoadMore}
        loadMoreText={(countInNextPage: number) => `Load more ${countInNextPage} posts`}
      />
    </Container>
  );
};

export default connect<StateHomeDefaultType, DispatchPropsType>(
  mapStateToProps,
  mapDispatchToProps,
)(HomeDefault);

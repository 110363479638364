import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StateBaseType } from 'moduleBase/types/StateBaseType';
import { BASE_REDUCER_NAME } from 'moduleBase/constants';
import { baseInit } from 'moduleBase/actions/init';

const initialState: StateBaseType = {
  isInit: false,
  isGuest: false,
  reCaptchaData: null,
  sentryData: null,
  isVisibleOnlyContent: false,
};

const baseSlice = createSlice({
  name: BASE_REDUCER_NAME,
  initialState,
  reducers: {
    setIsVisibleOnlyContent: (state, action: PayloadAction<boolean>) => {
      state.isVisibleOnlyContent = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(baseInit.fulfilled, (state, action) => {
      state.isInit = true;
      state.reCaptchaData = action.payload.reCaptchaData;
      state.sentryData = action.payload.sentryData;
      state.isGuest = action.payload.isGuest;
    });
  },
});

const baseReducer = baseSlice.reducer;

const { setIsVisibleOnlyContent } = baseSlice.actions;

export {
  baseReducer,
  setIsVisibleOnlyContent,
};

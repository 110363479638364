import {
  ReactNode,
  useEffect,
  useRef,
  FC,
} from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { When } from 'react-if';
import { AdvertPhotoType } from 'moduleAdvert/types/AdvertPhotoType';

type OptionalPropsType = {
  sm?: boolean;
  renderItem?: (photo: AdvertPhotoType) => ReactNode;
  additionalClassName?: string;
  isInitFancybox?: boolean;
};

type PropsType = OptionalPropsType & {
  photos: AdvertPhotoType[];
};

const Gallery: FC<PropsType> = ({
  photos,
  sm = false,
  renderItem = null,
  additionalClassName = null,
  isInitFancybox = false,
}) => {
  const refSwiper = useRef<HTMLDivElement>();
  const refSwiperNavigationNext = useRef<HTMLButtonElement>();
  const refSwiperNavigationPrev = useRef<HTMLButtonElement>();

  useEffect(() => {
    const promiseSwiper = import('swiper');
    const promiseSwiperModules = import('swiper/modules');
    Promise.all<[typeof promiseSwiper, typeof promiseSwiperModules]>(
      [promiseSwiper, promiseSwiperModules],
    ).then(([{ Swiper }, { Pagination, Navigation }]) => {
      if (!refSwiper.current) {
        return;
      }

      // eslint-disable-next-line no-new
      new Swiper(refSwiper.current, {
        modules: [Navigation, Pagination],
        navigation: {
          nextEl: refSwiperNavigationNext.current,
          prevEl: refSwiperNavigationPrev.current,
        },
      });
    });
  }, []);

  useEffect(() => {
    if (!isInitFancybox) {
      return undefined;
    }

    let fancybox = null;
    import('@fancyapps/ui/dist/fancybox/fancybox.esm').then(({ Fancybox }) => {
      fancybox = Fancybox;
      fancybox.bind('[data-fancybox]');
    });

    return () => {
      fancybox?.destroy();
    };
  }, []);

  const photoList = photos.map((photo) => {
    let content;
    if (renderItem) {
      content = renderItem(photo);
    } else {
      content = (
        <a href={photo.url} data-fancybox="gallery" data-slug={`gallery-${photo.id}`} aria-label={photo.url}>
          <img src={photo.url} className="gallery__image" />
        </a>
      );
    }

    return (
      <div className="swiper-slide" key={photo.id}>
        {content}
      </div>
    );
  });

  return (
    <div className={classNames('gallery', { gallery_sm: sm }, additionalClassName)}>
      <div className="swiper gallery__content" ref={refSwiper}>
        <div className="swiper-wrapper">
          {photoList}
        </div>
        <When condition={photos.length > 1}>
          <button
            type="button"
            className="gallery__control gallery__control_prev"
            ref={refSwiperNavigationPrev}
            aria-label="prev"
          >
            <FontAwesomeIcon icon="angle-left" />
          </button>
          <button
            type="button"
            className="gallery__control gallery__control_next"
            ref={refSwiperNavigationNext}
            aria-label="next"
          >
            <FontAwesomeIcon icon="angle-right" />
          </button>
        </When>
      </div>
    </div>
  );
};

export { Gallery };

// @ts-ignore
import layoutCss from 'srcCss/modules/layout.module.scss';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { FC, useRef } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useHeaderDataContext } from 'moduleBase/components/header/hooks/use-header-data-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser as faSolidUser } from '@fortawesome/free-solid-svg-icons';
import { faUser as faRegularUser } from '@fortawesome/free-regular-svg-icons';
import { LeftMenu } from 'moduleBase/components/header/left-menu/LeftMenu';
import { LeftMenuType } from 'srcJs/components/left-menu/types/LeftMenuType';
import { Else, If, Then } from 'react-if';
import { USER_LOGIN_PATH, USER_PROFILE_PATH } from 'moduleUser/constants';
import { useBaseContext } from 'moduleBase/hooks/use-base-context';
import { ActionLogout } from 'moduleBase/components/header/ActionLogout';

const Header: FC = () => {
  const refLeftMenu = useRef<LeftMenuType>();
  const {
    ref,
    isSticky,
  } = useHeaderDataContext();
  const {
    isGuest,
    isVisibleOnlyContent,
  } = useBaseContext();

  if (isVisibleOnlyContent) {
    return null;
  }

  const handlerClickOnLeftMenuToggle = () => {
    refLeftMenu.current.show();
  };

  return (
    <div
      ref={ref}
      className={classNames(
        layoutCss.header,
        'border-bottom border-white border-opacity-25',
        { [layoutCss.header_sticky]: isSticky },
      )}
    >
      <Container fluid="lg">
        <Navbar variant="dark" expand={false} className="d-flex justify-content-start gap-3">
          <Navbar.Toggle className="btn btn-unstyled p-0 border-0 shadow-none" onClick={handlerClickOnLeftMenuToggle}>
            <FontAwesomeIcon icon={faBars} className="fs-4" />
          </Navbar.Toggle>
          <Link to="/" className={layoutCss.header__logo}>
            SEO SECRETS
          </Link>
          <LeftMenu ref={refLeftMenu} />
          <If condition={isGuest}>
            <Then>
              <Link to={USER_LOGIN_PATH} className="btn btn-unstyled ms-auto">
                <FontAwesomeIcon icon={faRegularUser} className="fs-4" />
              </Link>
            </Then>
            <Else>
              <Link to={USER_PROFILE_PATH} className="btn btn-unstyled ms-auto">
                <FontAwesomeIcon icon={faSolidUser} className="fs-4" />
              </Link>
              <ActionLogout />
            </Else>
          </If>
        </Navbar>
      </Container>
    </div>
  );
};

export { Header };
